import React from 'react';
import { Button } from '@mui/material';
import { useOptionalFhir } from './OptionalFhir';
import config from './lib/config.js';

import styles from './About.module.css';

export default function About({ setTab, tabValues }) {

	const fhir = useOptionalFhir();

	const lnk = (text, url) => {
		return (<a href={url} target="_blank" rel="noreferrer">{text}</a>);
	}

	const renderTabButton = (tab, text) => {
		return (<p><Button variant='contained' onClick={() => setTab(tab)}>{text}</Button></p>);
	}

	const commonsLink = lnk("The Commons Project", "https://www.thecommonsproject.org/");
	const smartLink = lnk("SMART Health Cards and Links", "https://smarthealth.cards/");

	const srcLink = lnk("open source application",
		"https://github.com/the-commons-project/shc-web-reader");

	const covidLink = lnk("COVID-19 vaccine cards",
		"https://smarthealth.cards/en/find-my-issuer.html");

	const ipsLink = lnk("International Patient Summaries",
		"https://international-patient-summary.net/");


	return (
		<div className={styles.bodycontainer}>

			<div className={styles.parentcontainer}>

				<img src="qr_phone.jpg" alt="SMART Health Card" style={{ width: "450px" }} />
			</div>

			{/* <a href="https://veins.ips.reader.mhnexus.com/launch.html?iss=https://veinscdr.mhnexus.com/smartR4" target="_blank">
				<button>Open Example.com</button>
			</a> */}

			<h2>e Prescription Viewer</h2>

			{/* <div className={styles.content} >
				{config("showScan") && renderTabButton(tabValues.Scan, "Use a 2D barcode scanner")}
				{config("showPhoto") && renderTabButton(tabValues.Photo, "Use your camera")}
				{config("showFile") && renderTabButton(tabValues.File, "Open a file")}
				{fhir && config("showSearch") && renderTabButton(tabValues.Search, "Find a code in patient record")}
			</div> */}

			<div className={styles.deets} >
				<p>
					A ERX Viewer is a digital app designed to securely store and manage an individual's health information. It is typically used to facilitate easy access to health records, verify health status, and ensure seamless interaction with healthcare systems.
				</p>
				<p>
					Developed and maintained by MHNexus Sdn Bhd, this proprietary application can be used standalone or embedded within an EHR to read information in SMART Health Cards and Links. Supported data types currently include COVID-19 vaccine cards, general immunization records, International Patient Summaries, and Digital Health Insurance Cards.


				</p>
				{/* <p>
					If you would like to host the viewer yourself, contribute features or fixes
					to the project, or have any other questions, please contact {commonsLink}.
					Personal health information is processed exclusively in the browser and is
					never sent to the servers hosting the viewer.
				</p> */}
			</div>
			<img src="https://demo.vaxx.link/smart-logo.svg" alt="Smart Health Link"></img>
		</div>
	);
}

