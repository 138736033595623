import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, Select, MenuItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useOptionalFhir } from './OptionalFhir';
import { verifySHX, SHX_STATUS_NEED_PASSCODE, SHX_STATUS_OK } from './lib/SHX.js';
import { saveDivToPdfFile, saveDivToFHIR, downloadBundleToJSON } from './lib/saveDiv.js';
import { getDeferringCodeRenderer } from './lib/codes.js';
import * as res from './lib/resources.js';
import ValidationInfo from './ValidationInfo.js';
import WrongPatientWarning from './WrongPatientWarning.js';
import styles from './ValidationInfo.module.css';
import Coverage from './Coverage.js';
import ImmunizationHistory from './ImmunizationHistory.js'
import PatientSummary from './PatientSummary.js';
import styles2 from './App.module.css';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function Data({ shx }) {



	const [passcode, setPasscode] = useState(undefined);
	const [shxResult, setShxResult] = useState(undefined);
	const [bundleIndex, setBundleIndex] = useState(0);
	const [showSource, setShowSource] = useState(false);

	const [dcr, setDcr] = useState(getDeferringCodeRenderer());

	const fhir = useOptionalFhir();

	// +--------------------+
	// | renderNeedPasscode |
	// +--------------------+

	const passcodeRef = useRef(null);

	const passcodeClick = async () => {
		setPasscode(passcodeRef.current.value);
		setShxResult(undefined);
	}

	const passcodeKeyDown = (evt) => {
		if (evt.key === 'Enter') passcodeClick();
	}

	const renderNeedPasscode = () => {

		const msg = (passcode && Array.isArray(shxResult.reasons) && shxResult.reasons.length > 0)
			? shxResult.reasons[0]  // Display the specific error message from PasscodeError
			: "This SMART Health Link requires a passcode.";


		return (
			<>
				<br></br>
				<br></br>

				<center>

					<img src="passcode.jpg" alt="SMART Health Card" style={{ width: "150px" }} />
					<div>{msg}</div>

					<div>
						<TextField variant='outlined'
							margin='normal'
							type='password'
							autoComplete='off'
							autoFocus
							inputRef={passcodeRef}
							onKeyDown={passcodeKeyDown}
						/>
					</div>

					<div>
						<Button variant='contained'
							onClick={passcodeClick} >
							Submit
						</Button>
					</div>
				</center>


			</>
		);
	}

	// +-------------+
	// | renderError |
	// +-------------+

	const renderError = (reasons) => {

		// let displayReasons = Array.isArray(reasons) ? reasons : [reasons];
		// displayReasons = displayReasons.map(reason => reason.replaceAll("Error: ", ''));
		return (<div>hhh</div>);
	}


	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	function OnDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}
	// +---------------------+
	// | renderBundle        |
	// | renderBundleChooser |
	// +---------------------+

	const renderFile = () => {
		console.log("shxResult", shxResult);
		// var file = shxResult.file
		if (shxResult !== undefined) {

			const pdfData = `data:application/pdf;base64,${shxResult.file}`;
			console.log("PDF", pdfData);


			return (
				<>
					<div className={styles2.nav}>

						<Document file={pdfData} onLoadSuccess={OnDocumentLoadSuccess}>

							<Page pageNumber={pageNumber} renderTextLayer={false} scale={1.5} />
						</Document>

					</div >
				</>
			);
		} else {
			return (
				<>	<div >Loading</div></>);
		}

	}
	const PdfViewer = ({ base64, shxResult }) => {

		useEffect(() => {
			if (base64) {
				// Decode the base64 string
				const byteCharacters = atob(base64);
				const byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				const blob = new Blob([byteArray], { type: 'application/pdf' });

				// Create a URL for the Blob and set it in the iframe
				const url = URL.createObjectURL(blob);
				const iframe = document.getElementById('pdfViewer');
				iframe.src = url;

				// Clean up the object URL to prevent memory leaks
				return () => URL.revokeObjectURL(url);
			}
		}, [base64]); // Dependency on base64

		return (
			<div>
				<div>{shxResult.file}</div>
				<iframe id="pdfViewer" width="100%" height="600px" title="PDF Viewer"></iframe>
			</div>
		);
	};

	const renderBundle = () => {

		const bundle = shxResult.bundles[bundleIndex];

		const organized = (bundle.contentOK() ? bundle.organized : undefined);
		// var resultVerify = bundle.digitalsign.result;
		// let content;

		// if (resultVerify) {
		// 	content = (
		// 		<div className={styles.container}>
		// 			This card is <span className={styles.green}>signed </span>with digital certificate
		// 		</div>
		// 	);
		// } else {
		// 	content = (
		// 		<div className={styles.container}>
		// 			This card is <span className={styles.red}>not signed </span>with digital certificate
		// 		</div>
		// 	);
		// }

		let elt = undefined;

		if (organized) {

			switch (organized.typeInfo.btype) {

				case res.BTYPE_COVERAGE:
					elt = <Coverage organized={organized} dcr={dcr} />;
					break;

				case res.BTYPE_PS:
					elt = <PatientSummary organized={organized} dcr={dcr} />;
					break;

				case res.BTYPE_IMMUNIZATION:
					elt = <ImmunizationHistory organized={organized} dcr={dcr} />;
					break;

				// >>> ADD MORE RENDERERS HERE <<<

				default:
					elt = <pre><code>{JSON.stringify(bundle.fhir, null, 2)}</code></pre>;
					break;
			}
		}

		return (
			<>
				{renderBundleChooser()}
				<div id="bundle-contents">
					{/* <div>
						{content}
					</div> */}

					<ValidationInfo bundle={bundle} />
					<WrongPatientWarning organized={organized} />
					{elt}
				</div>
				<div>
					{elt && <Button onClick={() => onSaveClick(true)}>save to PDF</Button>}
					{elt && fhir && <Button onClick={() => onSaveClick(false)}>save to ehr</Button>}
					{elt && <Button onClick={() => downloadBundleToJSON(bundle.fhir, "fhir-bundle-data")}>Save as FHIR</Button>}
					<Button onClick={() => setShowSource(!showSource)}>source</Button>
					{showSource && <pre><code>{JSON.stringify(bundle, null, 2)}</code></pre>}
				</div>
			</>
		);
	}

	const onSaveClick = (toFile) => {

		// defensive because we can show in error cases too
		const baseName = (shxResult &&
			shxResult.bundles &&
			shxResult.bundles[bundleIndex] &&
			shxResult.bundles[bundleIndex].organized &&
			shxResult.bundles[bundleIndex].organized.typeInfo &&
			shxResult.bundles[bundleIndex].organized.typeInfo.label

			? shxResult.bundles[bundleIndex].organized.typeInfo.label
			: "Shared Information");

		const div = document.getElementById("bundle-contents");

		if (toFile) {
			saveDivToPdfFile(div, baseName);
		}
		else {
			saveDivToFHIR(fhir, div, baseName);
		}

		try {

			let patient = null;
			for (let i = 0; i < shxResult.bundles[0].fhir.entry.length; i++) {
				if (shxResult.bundles[0].fhir.entry[i].resource.resourceType == "Patient") {
					patient = shxResult.bundles[0].fhir.entry[i].resource;
				}
			}

			console.log("PATIENT ", patient);
			console.log("PATIENT identifier", patient.identifier[0].value);
			console.log("PATIENT source", patient.meta.source);
			// const response = fhir.create({
			// 	resource: shxResult.bundles[0].fhir
			// });

			let patientId = patient.id;
			let identifierValue = patient.identifier[0].value;
			let identifierSystem = patient.identifier[0].system;
			let source = patient.meta.source.split('#')[0];

			let bodyCreatePatient = {
				"resourceType": "Bundle",
				"id": "ips-bundle",
				"type": "transaction",
				"entry": [
					{
						"fullUrl": "Patient",
						"request": {
							"method": "POST",
							"url": "Patient/" + patientId,
							"ifNoneExist": "?identifier=" + identifierSystem + "|" + identifierValue + "&_source=" + source
						},
						"resource": patient
					}
				]

			};

			const responseCreatePatient = fhir.request({
				url: '', // Leave this empty for Bundles
				method: 'POST',
				body: JSON.stringify(bodyCreatePatient),
				headers: {
					'Content-Type': 'application/fhir+json'
				}
			});

			// const response = fhir.create(body);
			responseCreatePatient.then(responsePatient => {
				console.log("PATIENT ", responsePatient);
				// if (res.resourceType == "Bundle") {
				// 	alert('Save the ehr to the server successful');
				// }
				// else {
				// 	alert('Save the ehr to the server failed');
				// }
			});

			const responseCreateDocument = fhir.request({
				url: 'Bundle', // Leave this empty for Bundles
				method: 'POST',
				body: JSON.stringify(shxResult.bundles[0].fhir),
				headers: {
					'Content-Type': 'application/fhir+json'
				}
			});
			responseCreateDocument.then(responseDocument => {
				// console.log("DOCUMENT ", response);
				if (responseDocument.resourceType == "Bundle") {
					alert('Save the ehr to the server successful');
				}
				else {
					alert('Save the ehr to the server failed');
				}
			});



		} catch (error) {
			console.error('Error creating patient:', error);
		}
	}

	const onBundleChange = (evt) => {
		setBundleIndex(parseInt(evt.target.value));
	}

	const renderBundleChooser = () => {

		if (shxResult.bundles.length <= 1) return (undefined);

		const elts = [];
		for (const i in shxResult.bundles) {
			elts.push(<MenuItem key={i} value={i}>
				{shxResult.bundles[i].organized.typeInfo.label}
			</MenuItem>);
		}

		return (
			<>
				<span style={{ padding: "0px 8px 0px 6px" }} >
					Section {bundleIndex + 1} of {shxResult.bundles.length}:
				</span>
				<Select
					value={bundleIndex}
					sx={{ mb: 2 }}
					onChange={onBundleChange} >

					{elts}

				</Select>
			</>
		);
	}

	// +-------------+
	// | Main Render |
	// +-------------+

	useEffect(() => {

		verifySHX(shx, passcode)
			.then(result => {
				console.log("shx", result);
				setShxResult(result);
			})
			.catch(error => {
				// Handle the error appropriately
			});
	}, [shx, passcode]);


	useEffect(() => {
		const checkDcr = async () => { if (await dcr.awaitDeferred()) setDcr(getDeferringCodeRenderer()); }
		checkDcr();
	});

	if (shxResult && shxResult.shxStatus === SHX_STATUS_NEED_PASSCODE) {
		return (renderNeedPasscode());
	}

	if (shxResult && shxResult.shxStatus !== SHX_STATUS_OK) {
		// console.log("shxStatus", shxResult);
		return (renderError(shxResult.reasons));
	}


	return (renderFile());
}